<template>
	<div class="dm-content">
		<a-row :gutter="12">
			<a-col :span="24">
				<div>
					<zform
						:formConfig="searchFormConfig"
						:formData="{}"
						ref="duty-modal-form"
					></zform>
				</div>
				<div class="add-btn">
					<a-button type="" @click="goBack" style="margin-right: 8px">
						返回
					</a-button>
					<a-button type="primary" @click="uploadFile">
						上传文档
					</a-button>
				</div>
				<div>
					<ztable2
						:detailColumns="detailColumns"
						:columns="tableColumns"
						:dataSource="dataSource"
					>
						<span slot="customTitle" @click="previewVisible = true" style="display: flex;align-items: center;">
							文档编号<a-icon type="question-circle" />
							<!--  -->
						</span>
						<span slot="status" slot-scope="text, record">
							<a-badge status="success" text="已归档" />
						</span>
						<span slot="action" slot-scope="text, record">
							<div class="editable-row-operations">
								<a
									href="javascript:void(0)"
									@click="handleDownload(record)"
									>下载</a
								>
								<a
									href="javascript:void(0)"
									@click="handleEditFile(record)"
									v-if="record.position !== 'DIRECTOR'"
									>修改</a
								>
							</div>
						</span>
					</ztable2>
				</div>
			</a-col>
		</a-row>

		<a-modal
			class="ywh-modal"
			title="上传文档"
			:visible="uploadFileModalVisible"
			:confirm-loading="uploadFileLoading"
			@ok="zmodalHandleOk"
			@cancel="zmodalHandleCancel"
			ok-text="提交"
			cancel-text="取消"
		>
			<zform
				:formConfig="uploadFileFormConfig"
				:formData="{}"
				ref="modal-form"
			></zform>
		</a-modal>
		<a-modal
			:visible="previewVisible"
			:footer="null"
			width="60%"
			@cancel="previewVisible = false"
			@ok="previewVisible = false"
		>
			<img alt="example" style="width: 100%" src="https://huhuiyun.oss-cn-shanghai.aliyuncs.com/static/%E6%A1%A3%E6%A1%88%E7%AE%A1%E7%90%86%E8%A7%84%E5%88%99.png" />
		</a-modal>
	</div>
</template>

<script>
import Smodal from '@/components/modal/index.vue'
import {
	getFileMgtProjectById,
	createFileMgtFile,
	updateFileMgtFile,
} from '@/api/fileMgt/fileMgt'
import tableMixin from '@/mixin/table.list.js'

export default {
	data() {
		return {
			previewVisible: false,
			projectInfo: null,
			editingId: null,
			uploadFileLoading: false,
			treeData: [],
			searchFormConfig: {
				btnsCol: 8,
				btns: [
					{
						name: '重置',
						type: 'reset',
						clickHandle: ({ form, btn }) => {
							this.params.name = null
							this.params.documentNumber = null
							this.getList()
						},
					},
					{
						name: '搜索',
						type: 'save',
						styleType: 'primary',
						clickHandle: ({ form, btn }) => {
							const val = form.getFieldsValue()
							this.params.name = val.name
							this.params.documentNumber = val.documentNumber
							this.getList()
						},
					},
				],
				col: 8,
				fixedLabelWidth: '80px',
				// formLayout: 'vertical',
				textAlign: 'right',
				columns: [
					{
						props: 'documentNumber',
						type: 'input',
						label: '文档编号:',
						placeholder: '请输入文档编号',
						maxUpload: 1,
					},
					{
						props: 'name',
						type: 'input',
						label: '文档名称:',
						placeholder: '请输入文档名称',
					},
				],
			},
			detailColumns: [
				{ type: 'time', props: 'createdAt', format: 'YYYY-MM-DD' },
			],
			tableColumns: [
				{
					dataIndex: 'documentNumber',
					slots: { title: 'customTitle' },
    				scopedSlots: { customRender: 'documentNumber' },
				},
				{
					dataIndex: 'name',
					title: '文档名称',
				},
				{
					dataIndex: 'status',
					title: '状态',
					width: 120,
				},
				{
					dataIndex: 'createdAt',
					title: '上传时间',
					width: 130,
				},
				{
					dataIndex: 'createdBy',
					title: '上传人',
					width: 100,
				},
				{
					title: '操作',
					dataIndex: 'action',
					width: 140,
				},
			],
			params: {
				pageNum: 1,
				pageSize: 10,
				type: 2,
			},
			uploadFileModalVisible: false,
			uploadFileFormConfig: {
				col: 24,
				fixedLabelWidth: '80px',
				// formLayout: 'vertical',
				textAlign: 'right',
				columns: [
					{
						props: 'name',
						type: 'input',
						label: '文档名称:',
						placeholder: '请输入文档名称',
						rules: [
							{
								required: true,
							},
						],
					},
					{
						props: 'filePath',
						type: 'oss-upload',
						label: '文件',
						maxUpload: 1,
						listType: 'picture-card',
						rules: [
							{
								required: true,
							},
						],
					},
				],
			},
		}
	},
	mixins: [tableMixin],
	components: { Smodal },
	created() {
		this.initData()
	},
	methods: {
		goBack() {
			this.$router.replace(`/fileManagement/dynamic`)
		},
		handleDownload(record) {
			const { filePath } = record;
			if (!filePath || filePath.length == 0 || !filePath[0].url) {
				this.$message.error('暂未上传文件！请点击 修改 上传文件');
				return;
			}
			window.open(filePath[0].url)
		},
		initData() {
			let _id = this.$route.params.projectId
			getFileMgtProjectById(_id).then((res) => {
				this.projectInfo = res
				this.params.projectId = res._id
				this.params.classId = res.classId
				this.getList()
			})
		},
		handleEditFile(record) {
			this.editingId = record._id;
			this.uploadFileModalVisible = true;
			const finded = record.filePath.find(item => item.status === 'done');
			let fileList = finded ? [finded] : [];
			this.$nextTick(() => {
				this.$refs['modal-form'].updateForm({
					name: record.name,
					filePath: fileList,
				})
			})
		},
		// 查询列表接口api处理
		getListRequestHandle() {
			return {
				// 查询接口
				getApi: `/api/fileMgt/file`,
				usePagination: true,
			}
		},
		onSelect(keys, event) {
			this.params.classId = keys[0]
			this.getList()
		},
		zmodalHandleOk() {
			this.uploadFileLoading = true
			this.$refs['modal-form']
				.zformValidateFieldsAndScroll()
				.then(async (values) => {
					const uploadDone = values.filePath.find(item => item.status !== 'done');
					if (uploadDone) {
						this.$message.error('创建失败！请等待文件上传完毕！');
						this.uploadFileLoading = false
						return;
					}
					try {
						if (this.editingId) {
							await updateFileMgtFile(this.editingId, {
								name: values.name,
								classId: this.params.classId,
								projectId: this.params.projectId,
								filePath: values.filePath,
							})
						} else {
							await createFileMgtFile({
								name: values.name,
								classId: this.params.classId,
								projectId: this.params.projectId,
								filePath: values.filePath,
							})
						}
					} catch (error) {
						this.$message.error('创建失败！')
						console.log(error)
						this.uploadFileLoading = false
						return
					}

					this.$message.success('创建成功！')
					this.uploadFileLoading = false
					this.getList()
					this.zmodalHandleCancel()
				})
				.catch((err) => {
					console.log(err)
					this.uploadFileLoading = false
				})
		},
		zmodalHandleCancel() {
			this.$refs['modal-form'].resetFields()
			this.uploadFileModalVisible = false
		},
		uploadFile() {
			this.editingId = null
			if (!this.params.classId) {
				this.$message.warning('请选择一个目录！')
				return
			}
			this.uploadFileModalVisible = true
			console.log('uploadFileModalVisible', this.uploadFileModalVisible)
		},
	},
}
</script>

<style lang="less" scoped>
.dm-content {
	padding: 8px;
	background: white;
	height: 100%;
	width: 100%;
	.add-btn {
		text-align: right;
		margin: 12px 0px;
	}
}
</style>